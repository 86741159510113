import React, { Fragment } from 'react'
import { Layout } from '@components/Layout'
import { graphql } from 'gatsby'
import { mapToSEO, SEO } from '@components/SEO'
import { mapToPlateDesignGroupList, mapToPlateDesignList, mapToPlateDesignListMobile } from '@mappers/ProductModels/mapper'
import { MobileSelectStyle, DesktopSelectStyle } from '@components/SelectStyle'
import { PlateCategoryMetaInfo, ProductModelCategoryList, VehicleTypesSetting, TransactionTypesSetting, VehicleType, TransactionType, CurrentPlateDesign } from '@models/ProductModels'
import { DefaultCombination } from '@utils/Constant'
import { BasketLineResponseModel } from '@models/Basket/BasketLineResponseModel'
import { getVehicleTypeById, getTransactionTypeById, isMobile } from '@utils/Helpers'
import { Responsive, Loader } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { currentBasketSelector } from '@redux/selectors/basketSelector'
import { Record } from 'immutable'
import theme from '@styles/theme'
import NewMobileSelectStyle from '@components/SelectStyle/NewMobileSelectStyle'
import { IVehicleType } from '@models/ProductModels/VehicleTypes'
import { ITransactionType } from '@models/ProductModels/TransactionType'

interface Props {
  data: any,
  location: any,
  cartLineItems: BasketLineResponseModel[],
  flags?: any
}
interface States {
  plateDesignList?: {
    metaInfo: PlateCategoryMetaInfo,
    products: ProductModelCategoryList
  }[]
  selectedPlateDesign?: {
    metaInfo: PlateCategoryMetaInfo,
    products: ProductModelCategoryList
  }
  vehicleType: VehicleType
  transactionType: TransactionType
  combination: string
  currentPlateDesign: CurrentPlateDesign | undefined
  confirmStyle: boolean
  isGift: boolean
  newSelectStyle: boolean,
  groupList: any,
  groupName: any,
  productid: string
}

class FinalizePageView extends React.Component<Props, States>{
  constructor(props: Props) {
    super(props);

    let productId = ''
    if (typeof window !== "undefined") {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      productId = urlParams.get('productid') || ""
    }

    const derivedStates = this.readState(props.location);
    this.state = {
      plateDesignList: derivedStates.list,
      selectedPlateDesign: derivedStates.plateDesign,
      vehicleType: derivedStates.vt,
      transactionType: derivedStates.tt,
      combination: derivedStates.combination,
      currentPlateDesign: derivedStates.currentDesign,
      confirmStyle: derivedStates.confirmStyle,
      isGift: derivedStates.isGift,
      newSelectStyle: true,
      groupList: derivedStates.groupList,
      groupName: derivedStates.groupName,
      productid: productId
    }
  }

  readState = (location: any) => {
    var combination = "";
    if (typeof window !== "undefined") {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      combination = urlParams.get('combination') || ""
    }

    //redirect if combination not found
    if(typeof window !== 'undefined' && combination == ''){
      window.location.href = '/create';
    }

    var combinationFromUrl = ""
    var productId = ""
    var vtParam = ""
    var ttParam = ""
    if (typeof window !== "undefined") {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      combinationFromUrl = urlParams.get('combination') || ""
      productId = urlParams.get('productid') || ""
      vtParam = urlParams.get('vt') || ""
      ttParam = urlParams.get('tt') || ""
    }

    if(combinationFromUrl != ""){
      combination = combinationFromUrl;
    }

    var tt;
    switch (ttParam) {
      case "1":
        tt = TransactionTypesSetting.New
        break;
      case "2":
        tt = TransactionTypesSetting.Redesign
        break;
      case "3":
        tt = TransactionTypesSetting.Upgrade
        break;
      default:
        tt = TransactionTypesSetting.New
    }

    var vt;
    switch (vtParam) {
      case "1":
        vt = VehicleTypesSetting.Car
        break;
      case "2":
        vt = VehicleTypesSetting.Trailer
        break;
      case "3":
        vt = VehicleTypesSetting.Motorbike
        break;
      default:
        vt = VehicleTypesSetting.Car
    }

    const groupList = mapToPlateDesignGroupList(this.props.data.kontentItemSelectStylePageMobile.elements.styles.linked_items, combination, vt);
    var groupName = ""
    groupList.filter((y: any) =>
        y.items.filter((z: any) =>
            z.products.categories.filter((a: any) =>
                a.products.filter((b: any) =>
                    b.id === parseInt(productId)  ? groupName = y.groupName : ""
                )
            )
        )
    )

    if(location && location.state && location.state.vehicleType && location.state.transactionType){
      vt = location.state.vehicleType
      tt = location.state.transactionType
    }
    return this.loadPlateDesignList(combination, undefined, vt, tt, undefined, false, groupName, parseInt(productId))
  }

  loadPlateDesignListFromBasket = (basketLine: BasketLineResponseModel) => {
    const vt = getVehicleTypeById(basketLine.vehicleTypeId);
    const tt = getTransactionTypeById(basketLine.transactionTypeId);
    const list = mapToPlateDesignList(this.props.data.kontentItemSelectStylePage.elements.styles.linked_items, basketLine.combinationLayout, vt);
    const groupList = mapToPlateDesignGroupList(this.props.data.kontentItemSelectStylePageMobile.elements.styles.linked_items, basketLine.combinationLayout, vt);

    const plateDesign = list.find(item => item.products.getPlateCategoryByPlateDesignCode(basketLine.frontPlate.designId, basketLine.backPlate.designId) !== undefined);
    const currentDesign = plateDesign?.products.buildBasketPlateDesignProps(basketLine.frontPlate, basketLine.backPlate, basketLine.topCaption, basketLine.bottomCaption);
    if (currentDesign === undefined) {
      throw new Error("ERROR LOADING CART ITEM.");
    }
    return {
      list: list,
      plateDesign: plateDesign,
      vt: vt,
      combination: basketLine.combinationLayout,
      tt: tt,
      currentDesign: currentDesign,
      confirmStyle: false,
      isGift: basketLine.isGift,
      groupList: groupList,
      groupName: ""
    }
  }
  loadPlateDesignList = (formattedCombination?: string | undefined, selectedCategory?: string | undefined, vehicleType?: VehicleType | undefined, transactionType?: TransactionType | undefined, currentDesign?: CurrentPlateDesign | undefined, isGift?: boolean, groupName?: string, prodcutId?: number) => {

    const vt = vehicleType === undefined ? VehicleTypesSetting.Car : vehicleType;
    var combination = formattedCombination === undefined ? DefaultCombination.Car : formattedCombination;

    var combinationFromUrl = "";
    if (typeof window !== "undefined") {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      combinationFromUrl = urlParams.get('combination') || ""
    }

    if(combinationFromUrl != ""){
      combination = combinationFromUrl;
    }

    const tt = transactionType === undefined ? TransactionTypesSetting.New : transactionType;
    const list = mapToPlateDesignList(this.props.data.kontentItemSelectStylePage.elements.styles.linked_items, combination, vt);
    const groupList = mapToPlateDesignGroupList(this.props.data.kontentItemSelectStylePageMobile.elements.styles.linked_items, combination, vt);

    let plateDesign = prodcutId ? list.find(item => item.products.getPlateCategoryByPlateDesignCode(prodcutId, prodcutId) !== undefined) : list[0];
    if(plateDesign && prodcutId){
      const plateCategory = plateDesign.products.getPlateCategoryByPlateDesignCode(prodcutId, prodcutId);      
      if(plateCategory) {
        currentDesign = plateDesign.products.buildPlateDesignModel(plateCategory, plateCategory?.plateColor, plateCategory?.brand, undefined);
      }
    }    

    if (selectedCategory !== undefined && selectedCategory !== null) {
      const selectedPlateDesign = list.find(l => l.metaInfo.name === selectedCategory);
      if (selectedPlateDesign !== undefined) {
        plateDesign = selectedPlateDesign;
      }
    }
    let cd = currentDesign ===  undefined ? plateDesign!.products.buildDefaultPlateDesignProps() : currentDesign;
    let confirmStyle = currentDesign === undefined ? false : true;
    let isGifting = isGift === undefined ? false : isGift;
    return {
      list: list,
      plateDesign: plateDesign,
      vt: vt,
      combination: combination,
      tt: tt,
      currentDesign: cd,
      confirmStyle: confirmStyle,
      isGift: isGifting,
      groupList: groupList,
      groupName: groupName
    }
  }

  loadPlateDesignListFromDesignId = (combination: string, designId : number, vt: IVehicleType, tt: ITransactionType) => {

    const list = mapToPlateDesignGroupList(this.props.data.kontentItemSelectStylePageMobile.elements.styles.linked_items, combination, vt);
    let index = 0;
    for(var i = 0; i< list.length; i++){
      list[i].products.categories.forEach((category: { products: any[] }) =>{
        category.products.forEach(product => {
          if(product.id == designId) 
            index = i;
        })
      })
    }   
    const plateCategory = list[index].products.getPlateCategoryByPlateDesignCode(designId, designId);
    let currentDesign = undefined
    if(plateCategory) {
      currentDesign = list[index].products.buildPlateDesignModel(plateCategory, plateCategory?.plateColor, plateCategory?.brand, undefined);
    }
    
    return {
      vt: vt,
      combination: combination,
      tt: tt,
      currentDesign: currentDesign,
      confirmStyle: false,
      isGift: false,
      plateDesign: list[index] 
    }
  }

  onSelectedPlateDesign = (data: string) => {
    if (this.props.location.state === null || this.props.location.state === undefined) {
      const designList = this.loadPlateDesignList(undefined, data, undefined, undefined);
      const currentDesign = designList.plateDesign!.products.buildDefaultPlateDesignProps()
      this.setState({
        plateDesignList: designList.list,
        selectedPlateDesign: designList.plateDesign,
        vehicleType: designList.vt,
        transactionType: designList.tt,
        combination: designList.combination,
        currentPlateDesign: currentDesign,
        groupList: designList.groupList
      });

    } else {
      if (this.props.location.state.basketLine) {
        const { basketLine } = this.props.location.state;
        const vt = getVehicleTypeById(basketLine.vehicleTypeId);
        const tt = getTransactionTypeById(basketLine.transactionTypeId);
        const combination = basketLine.combinationLayout;
        const designList = this.loadPlateDesignList(combination, data, vt, tt);
        const currentDesign = designList.plateDesign!.products.buildDefaultPlateDesignProps();
        this.setState({
          plateDesignList: designList.list,
          selectedPlateDesign: designList.plateDesign,
          vehicleType: designList.vt,
          transactionType: designList.tt,
          combination: designList.combination,
          currentPlateDesign: currentDesign,
          groupList: designList.groupList
        });
      } else {
        const { combination, vehicleType, transactionType } = this.props.location.state;
        const designList = this.loadPlateDesignList(combination, data, vehicleType, transactionType);
        const currentDesign = designList.plateDesign!.products.buildDefaultPlateDesignProps()
        this.setState({
          plateDesignList: designList.list,
          selectedPlateDesign: designList.plateDesign,
          vehicleType: designList.vt,
          transactionType: designList.tt,
          combination: designList.combination,
          currentPlateDesign: currentDesign,
          groupList: designList.groupList
        });
      }

    }
  }

  componentDidMount(): void {
    if(this.state.productid){
      if(document){
        var section = document.querySelector('#p' + this.state.productid)
        var id = this.state.productid
        
        if(!section) {
          this.state.groupList.filter((y: any) =>
            y.items.filter((z: any) =>
                z.products.categories.filter((a: any) =>
                    a.products.filter((b: any) => b.id === parseInt(this.state.productid)  ? id = z.products.categories[0].products[0].id : "" )
                )
            )
          )
          this.setState({productid: id})
        }
        section = document.querySelector('#p' + id)
        setTimeout(() => {
          section?.scrollIntoView({behavior:'smooth', block:'start'})
        }, 1000);
      }
    }
  }

  render() {
    if(isMobile() === undefined){
      return null;
    }

    const { location, data } = this.props;
    const { elements } = data.kontentItemSelectStylePage;
    const apiUrl = data.site.siteMetadata.apiUrl;
    const seo = mapToSEO(elements);
    const { plateDesignList, selectedPlateDesign, transactionType, vehicleType,
      combination, currentPlateDesign, confirmStyle, isGift, groupList } = this.state;
    const title = this.props.data.kontentItemSelectStylePageMobile.elements.step_title.linked_items[1].elements.title.value;
    const description = this.props.data.kontentItemSelectStylePageMobile.elements.step_title.linked_items[1].elements.description.value;
    const subtitle = this.props.data.kontentItemSelectStylePageMobile.elements.step_title.linked_items[1].elements.subtitle.value;

    var displayStyle = true;
    var variation = true;

    return <Layout version="simple" currentStep={2} location={location}>
      <SEO {...seo} />
      {
        selectedPlateDesign === undefined || plateDesignList === undefined ? <Loader active inline='centered' conetnt="Loading..." /> :
          <Fragment>
            <Responsive minWidth={theme.responsive.tablet.minWidth}>
              <DesktopSelectStyle
                apiUrl={apiUrl}
                title={title}
                formattedCombination={combination}
                transactionType={transactionType}
                selectedPlateDesign={selectedPlateDesign}
                plateDesignList={plateDesignList}
                handleSelectCard={this.onSelectedPlateDesign}
                currentPlateDesign={currentPlateDesign!}
                vehicleType={vehicleType}
                isGift={isGift} />
            </Responsive>
          </Fragment>
      }
    </Layout>
  }
}

export const query = graphql`{
  site {
    siteMetadata {
      apiUrl
    }
  }
  kontentItemSelectStylePage {
    elements {
      seo__noindex {
        value {
          name
          codename
        }
      }
      seo__nofollow {
        value {
          name
          codename
        }
      }
      seo__page_title {
        value
      }
      seo__page_description {
        value
      }
      page_info___checkout__title {
        value
      }
      url {
        value
      }
      styles {
        linked_items {
          ... on KontentItemProductCategory {
            elements {
              category_name {
                value
              }
              lock_caption_colors {
                value {
                  codename
                  name
                }
              }
              category_description_rich {
                value
              }
              vehicle_types {
                value {
                  name
                  codename
                }
              }
              new_design {
                value {
                  codename
                  name
                }
              }
              fixed_fomat_price {
                value
              }
              flexi_format_price {
                value
              }
              upgrade_starting_price {
                value
              }
              redesign_starting_price {
                value
              }
              premium_3_character_price {
                value
              }
              premium_2_character_price {
                value
              }
              product_designs {
                linked_items {
                  ... on KontentItemPlateDesign {
                    elements {
                      name {
                        value
                      }
                      dealer_only_design {
                        value {
                          name
                          codename
                        }
                      }
                      disable_dual_sizing {
                        value {
                          codename
                          name
                        }
                      }
                      authorized_dealers {
                        linked_items {
                          ... on KontentItemAuthorizedDealers {
                            elements {
                              title {
                                value
                              }
                              link_message {
                                value
                              }
                              description {
                                value
                              }
                              dealer_contacts {
                                linked_items {
                                  ... on KontentItemDealerContact {
                                    elements {
                                      dealer_name {
                                        value
                                      }
                                      contact_name {
                                        value
                                      }
                                      phone {
                                        value
                                      }
                                      formatted_phone_number {
                                        value
                                      }
                                      dealer_id {
                                        value
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                      transaction_types {
                        value {
                          name
                          codename
                        }
                      }
                      vehicle_types {
                        value {
                          name
                          codename
                        }
                      }
                      kpi_plate_design_code {
                        value {
                          name
                          codename
                        }
                      }
                      kpi_plate_foreground_color {
                        value {
                          name
                          codename
                        }
                      }
                      plate_size {
                        value {
                          name
                          codename
                        }
                      }
                      plate_color {
                        value {
                          name
                          codename
                        }
                      }
                      base_price {
                        value
                      }
                      redesign_price {
                        value
                      }
                      govt_upgrade_price {
                        value
                      }
                      captions {
                        value {
                          name
                          codename
                        }
                      }
                      max_message_length__mm_ {
                        value
                      }
                      bottom_caption_max_length__mm_ {
                        value
                      }
                      message_colors {
                        value {
                          name
                          codename
                        }
                      }
                      european_brand_name {
                        value {
                          name
                          codename
                        }
                      }
                      dual_size_plate {
                        linked_items {
                          ... on KontentItemPlateDesign {
                            elements {
                              name {
                                value
                              }
                              dealer_only_design {
                                value {
                                  name
                                  codename
                                }
                              }
                              disable_dual_sizing {
                                value {
                                  codename
                                  name
                                }
                              }
                              authorized_dealers {
                                linked_items {
                                  ... on KontentItemAuthorizedDealers {
                                    elements {
                                      title {
                                        value
                                      }
                                      link_message {
                                        value
                                      }
                                      description {
                                        value
                                      }
                                      dealer_contacts {
                                        linked_items {
                                          ... on KontentItemDealerContact {
                                            elements {
                                              dealer_name {
                                                value
                                              }
                                              contact_name {
                                                value
                                              }
                                              phone {
                                                value
                                              }
                                              formatted_phone_number {
                                                value
                                              }
                                              dealer_id {
                                                value
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                              transaction_types {
                                value {
                                  name
                                  codename
                                }
                              }
                              vehicle_types {
                                value {
                                  name
                                  codename
                                }
                              }
                              kpi_plate_design_code {
                                value {
                                  name
                                  codename
                                }
                              }
                              kpi_plate_foreground_color {
                                value {
                                  name
                                  codename
                                }
                              }
                              plate_size {
                                value {
                                  name
                                  codename
                                }
                              }
                              plate_color {
                                value {
                                  name
                                  codename
                                }
                              }
                              base_price {
                                value
                              }
                              redesign_price {
                                value
                              }
                              govt_upgrade_price {
                                value
                              }
                              captions {
                                value {
                                  name
                                  codename
                                }
                              }
                              max_message_length__mm_ {
                                value
                              }
                              bottom_caption_max_length__mm_ {
                                value
                              }
                              message_colors {
                                value {
                                  name
                                  codename
                                }
                              }
                              european_brand_name {
                                value {
                                  name
                                  codename
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              feature_image {
                value {
                  url
                }
              }
              feature_image_mobile {
                value {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
  kontentItemSelectStylePageMobile {
    elements {
      page_info___checkout__title {
        value
        name
      }
      page_info___checkout__progress {
        value {
          name
          codename
        }
        name
      }
      url {
        value
      }
      step_title {
        linked_items {
          ... on KontentItemSelectStyleStep {
            elements {
              title {
                value
              }
              description {
                value
              }
              subtitle {
                value
              }
            }
          }
        }
      }
      styles {
        name
        type
        linked_items {
          ... on KontentItemSelectStyleGroup {
            id
            elements {
              group_name_to_display {
                value
                name
              }
              group_name {
                name
                linked_items {
                  ... on KontentItemProductCategory {
                    id
                    elements {
                      category_name {
                        value
                      }
                      lock_caption_colors {
                        value {
                          codename
                          name
                        }
                      }
                      category_description_rich {
                        value
                      }
                      vehicle_types {
                        value {
                          name
                          codename
                        }
                      }
                      new_design {
                        value {
                          codename
                          name
                        }
                      }
                      fixed_fomat_price {
                        value
                      }
                      flexi_format_price {
                        value
                      }
                      upgrade_starting_price {
                        value
                      }
                      redesign_starting_price {
                        value
                      }
                      premium_3_character_price {
                        value
                      }
                      premium_2_character_price {
                        value
                      }
                      product_designs {
                        linked_items {
                          ... on KontentItemPlateDesign {
                            elements {
                              name {
                                value
                              }
                              dealer_only_design {
                                value {
                                  name
                                  codename
                                }
                              }
                              disable_dual_sizing {
                                value {
                                  codename
                                  name
                                }
                              }
                              authorized_dealers {
                                linked_items {
                                  ... on KontentItemAuthorizedDealers {
                                    elements {
                                      title {
                                        value
                                      }
                                      link_message {
                                        value
                                      }
                                      description {
                                        value
                                      }
                                      dealer_contacts {
                                        linked_items {
                                          ... on KontentItemDealerContact {
                                            elements {
                                              dealer_name {
                                                value
                                              }
                                              contact_name {
                                                value
                                              }
                                              phone {
                                                value
                                              }
                                              formatted_phone_number {
                                                value
                                              }
                                              dealer_id {
                                                value
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                              transaction_types {
                                value {
                                  name
                                  codename
                                }
                              }
                              vehicle_types {
                                value {
                                  name
                                  codename
                                }
                              }
                              kpi_plate_design_code {
                                value {
                                  name
                                  codename
                                }
                              }
                              kpi_plate_foreground_color {
                                value {
                                  name
                                  codename
                                }
                              }
                              plate_size {
                                value {
                                  name
                                  codename
                                }
                              }
                              plate_color {
                                value {
                                  name
                                  codename
                                }
                              }
                              base_price {
                                value
                              }
                              redesign_price {
                                value
                              }
                              govt_upgrade_price {
                                value
                              }
                              captions {
                                value {
                                  name
                                  codename
                                }
                              }
                              max_message_length__mm_ {
                                value
                              }
                              bottom_caption_max_length__mm_ {
                                value
                              }
                              message_colors {
                                value {
                                  name
                                  codename
                                }
                              }
                              european_brand_name {
                                value {
                                  name
                                  codename
                                }
                              }
                              dual_size_plate {
                                linked_items {
                                  ... on KontentItemPlateDesign {
                                    elements {
                                      name {
                                        value
                                      }
                                      dealer_only_design {
                                        value {
                                          name
                                          codename
                                        }
                                      }
                                      disable_dual_sizing {
                                        value {
                                          codename
                                          name
                                        }
                                      }
                                      authorized_dealers {
                                        linked_items {
                                          ... on KontentItemAuthorizedDealers {
                                            elements {
                                              title {
                                                value
                                              }
                                              link_message {
                                                value
                                              }
                                              description {
                                                value
                                              }
                                              dealer_contacts {
                                                linked_items {
                                                  ... on KontentItemDealerContact {
                                                    elements {
                                                      dealer_name {
                                                        value
                                                      }
                                                      contact_name {
                                                        value
                                                      }
                                                      phone {
                                                        value
                                                      }
                                                      formatted_phone_number {
                                                        value
                                                      }
                                                      dealer_id {
                                                        value
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                      transaction_types {
                                        value {
                                          name
                                          codename
                                        }
                                      }
                                      vehicle_types {
                                        value {
                                          name
                                          codename
                                        }
                                      }
                                      kpi_plate_design_code {
                                        value {
                                          name
                                          codename
                                        }
                                      }
                                      kpi_plate_foreground_color {
                                        value {
                                          name
                                          codename
                                        }
                                      }
                                      plate_size {
                                        value {
                                          name
                                          codename
                                        }
                                      }
                                      plate_color {
                                        value {
                                          name
                                          codename
                                        }
                                      }
                                      base_price {
                                        value
                                      }
                                      redesign_price {
                                        value
                                      }
                                      govt_upgrade_price {
                                        value
                                      }
                                      captions {
                                        value {
                                          name
                                          codename
                                        }
                                      }
                                      max_message_length__mm_ {
                                        value
                                      }
                                      bottom_caption_max_length__mm_ {
                                        value
                                      }
                                      message_colors {
                                        value {
                                          name
                                          codename
                                        }
                                      }
                                      european_brand_name {
                                        value {
                                          name
                                          codename
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                      feature_image_mobile {
                        name
                        value {
                          url
                        }
                      }
                      feature_image {
                        name
                        value {
                          url
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

`

const mapStateToProps = (state: Record<any>) => {
  const cart = currentBasketSelector(state).toJS();
  return {
    cartLineItems: cart.basketLines,
  };
}

const FinalizePage = connect(mapStateToProps)(FinalizePageView)
export default FinalizePage;